@import '~pretty-checkbox/src/pretty-checkbox.scss';

.App {
  text-align: center;
  font-size:15px;
  padding-top:20px;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.rolls {
  margin-top:1rem;
  position:relative;

  h3 {
    text-align:left;
  }

  .clear-icon {
    position:absolute;
    top:0;
    right:0;
    cursor:pointer;
    svg {
      width:2.2rem;
      height:2.2rem;
      fill:#DC3545;
    }
  }
}

.roll-select .die-wrapper {
  cursor:pointer;
}

.die-wrapper.small {
  .die, .die-shape {
    width: 2.2rem;
    height: 2.2rem;
  }
  .die-number {
    font-size:1.1rem;
  }
}

.inline-block {
  display:inline-block;
}

.buttons {
  margin-top:0.7rem;
  button {
    margin:0 0.3rem 0 0;
    &.margin-left {
      margin-left:0.7rem;
    }
  }
  .btn.btn-sm {
    padding:0.5rem 1rem;
  }
      input[type="text"] {
      width:3.3rem;
      display:inline-block;
    }
  .mod-button {
    white-space: nowrap;
    display: inline-block;
  }

  .pretty {
    font-size:1.2rem;
  }
    /*
    select {
      display:inline;
      width:4.1em;
      height:2.1em;
      vertical-align:middle;
      text-align:center;
      padding:0.375rem 0.2rem 0.375rem 0.1rem;
    }
*/
  .inline-block:first-child {
     margin-right:0.5rem;
  }

  .inline-block:last-child {
    margin-left:0.5rem;
  }

  .md-form.md-outline {
    display:inline-block;
    margin:0;
    top:0.2rem;
  }
}

/*
.App {
  margin-top:0;
}
*/

.navbar {
/*
  position:relative;
  top:-20px;
*/
}


.content {
  padding:0 0.7rem;
}

.roll {
  border:1px solid #000000;
  margin:0 0 0.7rem 0;

  .rolled-dice, .result-icons {
    padding:0.7rem;
    position:relative;
  }

  .rolled-dice {
    border-bottom:1px solid #000000;
    padding-top:1.4rem;
  }

}

.success-failure {
  //font-size:15px;
  font-weight:bold;
  svg {
    width:20px;
    height:20px;
    vertical-align:bottom;
  }
  &.success {
    color:#28A745;
    svg {
      fill:#28A745;
    }
  }
  &.failure {
    color:#DC3545;
    svg {
      fill:#DC3545;
    }
  }
}


.die, .die-shape, .die-symbol {
  height:2.8rem;
  width:2.8rem;
}

.die, .die-wrapper, .result-icon-wrapper {
  display:inline-block;
  position:relative;
}

.die-shape {
  position:relative;
  top:0;
  left:0;
  stroke-width:2;
}

.die-symbol, .die-number {
  position:absolute;
  width:70%;
  height:70%;
  top:15%;
  left:15%;
}

.die-number {
  color:#FFFFFF;
  font-weight:bold;
  font-size:1.4rem;
  vertical-align:middle;
  width:100%;
  left:0;
}

.die svg {
  height:100%;
  width:100%;
  background-color:transparent !important;
}

.result-sum {
  span {
    font-size:1.4rem;
    display:inline-block;
    position:relative;
    top:0.28rem;
    left:0.25rem;
  }
  font-weight:bold;
}

.result-icon-wrapper svg {
  width:2.8rem;
  height:2.8rem;
  background-color:transparent !important;
}

.die-wrapper .badge, .result-icon-wrapper .badge {
  position:absolute;
  bottom:0;
  right:0;
  font-size:0.7rem;
}

.result-icon-wrapper .badge {
  font-size:0.7rem;
}

.roll-datetime {
  position:absolute;
  top:0;
  left:0;
  font-size:0.8rem;
/*
  border-right:1px solid #000000;
  border-bottom:1px solid #000000;
*/
  padding:3px 5px;
  font-weight:bold;
}

input:invalid, input:focus:invalid {
  border:1px solid #FF0000;
}

.nav-link.selected {
  background-color:rgba(255, 255, 255, 0.1);
}

.card {
  margin-bottom:1rem;
  .card-body {
    padding:1.5rem 1rem 1rem 1rem;
  }
  hr {
    margin:0.75rem 0;
  }
}

.die-shape, .content {
  .yellow {
    fill:#FFE902;
  }

  .red {
    fill:#D2232A;
  }

  .green {
    fill:#40AE49;
  }

  .purple {
    fill:#532381;
  }

  .light-blue {
    fill:#72CDDC;
  }

  .blue {
    fill:#0d47a1;
  }

  .black {
    fill:#000000;
  }

  .white {
    fill:#FFFFFF;
  }
}

.die-symbol {
  .white {
    fill:#FFFFFF;
    stroke:#FFFFFF;
  }

  .black {
    fill:#000000;
    stroke:#000000;
  }
}

.green-text {
  color:#64dd17 !important;
}
